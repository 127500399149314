div.b1-page-header .c-header.c-header--static {
  margin-bottom: 48px;
}

/* Display page title below logo on smaller screens and in context bar on larger screens */
div.b1-page-header .c-context-bar__link {
  margin-left: 7px;
}

div.b1-page-header .c-context-bar__link span {
  font-size: 0.83333rem;
  font-weight: bold;
}

div.b1-page-header .c-context-bar__icon {
  font-size: 1.33333rem;
}

@media (max-width: 720px) {
  div.b1-page-header .c-context-bar__title {
    display: none;
  }
}

div.b1-header-title {
  display: none;
}

div.b1-header-title h1 {
  font-size: 1rem;
  margin-bottom: 0;
}

@media (max-width: 720px) {
  div.b1-header-title {
    display: inherit;
  }
}
