#downPaymentAmount.c-input__input::-webkit-outer-spin-button,
#downPaymentAmount.c-input__input::-webkit-inner-spin-button,
#downPaymentAmount.c-input__input {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield !important;
}

#chiModal .c-modal__content {
  max-height: 75vh;
}
